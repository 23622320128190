export enum LOGLEVEL {
    ERROR,
    WARN,
    INFO,
    DEBUG
}
export class AppConstants {
    public static LogLevel = LOGLEVEL.DEBUG;
    public static DateFormat = 'YYYY-MM-DD';
    public static SUCCESS = 'success';
    public static FAIL = 'fail';
    public static Prefix =  'api' // 'irsf-rest';

    public static pageSize = '';
    public static downloadPageSize = 40000;
    public static DELIMITER = '|';
    public static MaxListUploadFileSize = 10000000;
    public static MaxListSlot = 3;
    public static MaxPartitionSlot = 3;
    public static ShowMoreLimit = 15;
    public static ThresholdWarningLimit = 3;

    public static UserEndPoint = AppConstants.Prefix + '/user';
    public static WebSocketEndPoint = AppConstants.Prefix + '/websocket';
    public static QueryUserEventsURL = AppConstants.Prefix + '/userEvents';
    public static LoginURL = AppConstants.Prefix + '/login';
    public static LogoutURL = AppConstants.Prefix + '/logout';
    public static Auth0LogoutURL = AppConstants.Prefix + '/logoutUrl';
    public static QueryCountryURL = AppConstants.Prefix + '/country';
    public static QueryProviderURL = AppConstants.Prefix + '/providers';
    public static QueryMsrnProviderURL = AppConstants.Prefix + '/msrnproviders';
    public static QueryGuiPollingEnabled = AppConstants.Prefix + '/guiPollingEnabled';

    public static QueryTosURL = AppConstants.Prefix + '/tos';
    public static QueryTosProviderURL = AppConstants.Prefix + '/tosproviders';
    public static QueryCountryProviderURL = AppConstants.Prefix + '/countryproviders';

    public static QueryRangeNDCURL = AppConstants.Prefix + '/rangeNDC';
    public static QueryRangeNdcPageURL = AppConstants.Prefix + '/findRangeNdc';
    public static QueryPremiumPageURL = AppConstants.Prefix + '/findPremium';
    public static DownloadMobileIdDataURL = AppConstants.Prefix + '/download/';
    public static QueryMsrnPageURL = AppConstants.Prefix + '/findMsrn';
    
    public static QueryBlackListURL = AppConstants.Prefix + '/lists/BL';
    public static QueryWhiteListURL = AppConstants.Prefix + '/lists/WL';
    public static QueryTPListURL = AppConstants.Prefix + '/lists/TP';
    public static QueryListDetailData = AppConstants.Prefix + '/listDetail';
    public static QueryLatestListUploadRequest = AppConstants.Prefix + '/latestUploadRequest';
    public static QueryListDefinition = AppConstants.Prefix + '/list';

    
    public static SaveListDefinition = AppConstants.Prefix + '/list/update';
    public static DeleteListDefinition = AppConstants.Prefix + '/list';
    public static UploadListFile = AppConstants.Prefix + '/uploadListFile';

    public static HasListDetailRecord = AppConstants.Prefix + '/hasListDetails/';
    public static GetDialPatternDetails = AppConstants.Prefix + '/dialPattern/';
    public static CreateListDetail = AppConstants.Prefix + '/listDetails/create';
    public static UpdateListDetail = AppConstants.Prefix + '/listDetails/update';
    public static DeleteListDetail = AppConstants.Prefix + '/listDetails/delete';
    public static DeleteAllListRecords = AppConstants.Prefix + '/list/clear';
    public static ToggleActivity = AppConstants.Prefix + '/listDetails/updateStatus';
    
    public static QueryPartitionListURL = AppConstants.Prefix + '/partitions';
    public static QueryPartitionURL = AppConstants.Prefix + '/partition/';
    public static UpdatePartitionURL = AppConstants.Prefix + '/partition/save';
    public static UpdatePartitionBlackListURL = AppConstants.Prefix + '/partition/updateBlackList';
    public static UpdatePartitionTrustedPartitionURL = AppConstants.Prefix + '/partition/updateTPList';
    public static UpdatePartitionWhiteListURL = AppConstants.Prefix + '/partition/updateWhiteList';
    public static RefreshPartitionURL = AppConstants.Prefix + '/partition/refresh/';
    public static ExportPartitionURL = AppConstants.Prefix + '/partition/export/';
    public static ResendPartitionURL = AppConstants.Prefix + '/partition/resend/';
    public static QueryDraftDataURL = AppConstants.Prefix + '/draftdata';
    public static QueryDownloadPartitionDataURL = AppConstants.Prefix + '/partition/download';

    public static CreateDraftDataURL = AppConstants.Prefix + '/createdraftdata';
    public static QueryDraftDataFilterURL = AppConstants.Prefix + '/draftdatafilters';
    public static QueryExportDataFilterURL = AppConstants.Prefix + '/exporttdatafilters';
    public static QueryDraftDataFilterURLv2 = AppConstants.Prefix + '/draftdatafilters2';
    public static QueryExportDataFilterURLv2 = AppConstants.Prefix + '/exporttdatafilters2';
    public static QueryExportDataURL = AppConstants.Prefix + '/exportdata';
    public static DeletePartitionURL = AppConstants.Prefix + '/partition/';
    public static RemoveRuleFromPartitionURL = AppConstants.Prefix + '/partition/';
    public static DeleteAllPartitionRulesURL = AppConstants.Prefix + '/partition/rules/';
    public static QueryPartitionReferences = AppConstants.Prefix + '/partitionReferences';
    public static QueryPartitionExportInProgress = AppConstants.Prefix + '/exportInProgress/';
    public static DownloadBlockingFile = AppConstants.Prefix + '/blockingfile/';
    public static CreateRuleURL = AppConstants.Prefix + '/rule/create';
    public static UpdateRuleURL = AppConstants.Prefix + '/rule/update';

    public static TrustedPartnerListURL = AppConstants.Prefix + '/list/msrndata';
    public static QueryMsrnProviderListData = AppConstants.Prefix + '/msrnProviderListData';
    public static SaveTrustedPartnerListDefinition = AppConstants.Prefix + '/trustedPartner/update';

    public static QueryRemainingListAllotment = AppConstants.Prefix + '/remaininglistAllotment';
    public static QueryMaxValues = AppConstants.Prefix + '/maxValues';
    public static QueryRemainingPartitionAllotmentURL = AppConstants.Prefix + '/partitions/remainingAllotment';
    public static QueryRemainingFileLayoutsURL = AppConstants.Prefix + '/filelayout/remainingAllotment';

    public static GetFileLayoutFieldsURL = AppConstants.Prefix + '/filelayout/fields';
    public static GetFileLayoutDelimitersURL = AppConstants.Prefix + '/filelayout/delimiters';
    public static QueryFileLayoutListURL = AppConstants.Prefix + '/filelayouts';
    public static SaveFileLayoutDefinitionURL = AppConstants.Prefix + '/filelayout/save';
    public static QueryFileLayoutPartitionReferences = AppConstants.Prefix + '/partitions/filelayout/references';
    public static QueryFileLayoutURL = AppConstants.Prefix + '/filelayout';
    public static DeleteFileLayoutURL = AppConstants.Prefix + '/filelayout';

    public static QueryCustomerSftpConfigured = AppConstants.Prefix + '/sftpConfigured';

    public static LogUIActivityURL = AppConstants.Prefix + '/logUIActivity';

    
    //Country Group URLS
    public static CreateCountryGroup = AppConstants.Prefix + '/customGroup/create'; //POST
    public static SaveCountryGroup = AppConstants.Prefix + '/customGroup/update'; //POST
    public static DeleteCountryGroup = AppConstants.Prefix + '/customGroup'; // DELETE
    public static QueryCountryGroupURL = AppConstants.Prefix + '/customGroup' // GET
    public static QueryCountryGroupsURL = AppConstants.Prefix + '/customGroups/CountryGroup' // GET 
    //SO FAR NOT USED AT ALL
    public static QueryRemainingCustomCountryGroupAllotmentURL = AppConstants.Prefix + '/customGroups/remainingAllotment/CountryGroup'; //GET 

    //use for sleep call await AppConstants.delay(1000)
    public static delay = (ms: number) => {
        return new Promise<void>(function (resolve) {
            setTimeout(resolve, ms);
        });
    }

    public static inactivityInterval = AppConstants.Prefix + '/inactivityInterval'
    public static inactivityTimeout = 30;
}

