import { Type, Skip } from 'serializer.ts/Decorators';
import { ListDefinition } from '../models/listDefinition.model';
//import {Customer} from './customer.model';
// map to UserDefinition.java

export class User {
    id: string;
    userName: string;
    password: string;
    token: string;

    //@Skip()
    cpassword: string;
    role: string;
    customerId: number;
    customerName: string;
    schemaName: string;
    firstName: string;
    lastName; string;
    phone: string;
    email: string;
    locked: boolean;
    disabled: boolean;
    createTimestamp: Date;
    lastUpdated: Date;
    lastUpdatedBy: string;

    hasList: boolean;
    showTerms: boolean;
    guiPollingEnabled: boolean;
    
    listDefinition: ListDefinition;
    valid: boolean;
    active: boolean;

    //@Type(() => Customer)
    //customerDefinition: Customer;

    constructor() {}

}