import { Component } from '@angular/core';
import { AuthService } from './auth';
import { Router, ActivatedRoute } from '@angular/router';
import {NotificationService} from './services/notification.service';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

    title = 'TruNumber PROTECT';
    shortTitle = 'TNP';
    year = 2021;

    constructor( private router: Router, public authService: AuthService, public notifService: NotificationService) {
        const dt = new Date();
        this.year = dt.getFullYear();
    }

    logout(): void {
        this.authService.logout();
    }

	isErrorPage() {
		return this.router.url.includes("errorMsg");
	}
}