import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JsonHelper } from '../util/json.helper';
import { ResponseJson } from '../models/response.model';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import {EventService} from '../services/event.service';
//import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { AppConstants, CB } from '../util/index';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';

const headers = new HttpHeaders().set('Accept', 'application/json');

@Injectable()
export class AuthService implements OnDestroy {
  loginUser: User;
  private storageEventListener: (event: StorageEvent) => void;
  constructor(private http: HttpClient, private httpService: HttpService, private log: Logger, private eventService: EventService) {
    this.storageEventListener = this.handleStorageEvent.bind(this);
    window.addEventListener('storage', this.storageEventListener);
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener);
  }

  $authenticationState = new BehaviorSubject<boolean>(false);


  getUser(): Observable<User> {
    return this.http.get<User>(AppConstants.UserEndPoint, { headers },)
      .pipe(map((response: User) => {
          if (response !== null) {
            this.$authenticationState.next(true);
          }
          return response;
        })
      );
  }

  async isAuthenticated(): Promise<boolean> {
    this.loginUser = await lastValueFrom(this.getUser());
    return this.loginUser !== null;
  }




  logout(): void {
	if (!this.isAuthorized()) {
		// Take user back to login page
		window.location.assign(window.location.origin + '/');
	}
	else {
    	// remove user from local storage to log user out
    	localStorage.setItem('logout-event', String(Date.now()));
    	sessionStorage.removeItem('currentUser');
    	if (this.eventService && this.eventService.pullUserEventUpdate) {
      	  this.eventService.pullUserEventUpdate.unsubscribe();
    	}
    	this.httpService.get(AppConstants.Auth0LogoutURL,[this.postGetAuth0LogoutURLHandler]);
    	localStorage.removeItem('logout-event');
    }
  }

  postGetAuth0LogoutURLHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      window.location.assign(responseJson.data.logoutUrl);
    }
  }

  login(): void {
    window.location.href =  window.location.origin +   "/oauth2/authorization/okta";
  }



  oldlogin(user: User, successCallback: CB, errCallback: CB): void {
    const myCallbacks: CB[] = [];
    myCallbacks.push(successCallback);
    myCallbacks.push(errCallback);

    this.httpService.post(JsonHelper.toJson(user), AppConstants.LoginURL, myCallbacks);
  }


  isAuthorized(): boolean {
    return sessionStorage.getItem('currentUser') != null;
  }

  hasRole(role: string, ...otherRoles: string[]): boolean {

    if (sessionStorage.getItem('currentUser') == null) {
      return false;
    }

    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (loginUser.role === role) {
      return true;
    } else if (otherRoles && otherRoles.indexOf(loginUser.role) >= 0) {
      return true;
    }

    return false;
  }

  isActive(): boolean{
    if (sessionStorage.getItem('currentUser') == null) {
      return false;
    }
    const loginUser:User = JSON.parse(sessionStorage.getItem('currentUser'));
    return loginUser.active; 
  }

  isValid(): boolean{
    if (sessionStorage.getItem('currentUser') == null) {
      return false;
    }
    const loginUser:User = JSON.parse(sessionStorage.getItem('currentUser'));
    return loginUser.valid; 
  }



  isLoggedInAndHasList(){
    if (sessionStorage.getItem('currentUser') == null) {
      return false;
    }
    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (loginUser.hasList) {
      return true;
    }
    return false;
  }

  private handleStorageEvent(event: StorageEvent): void {
    //Inside the event listener, check if the key of the changed localStorage item matches a predefined key for logout events (e.g., 'logout-event').
    //If the key matches, execute the logout logic, which may include clearing the user's session, removing authentication tokens, and navigating to the login page.
    if (event.key === 'logout-event' && event.newValue) {
      // Clear user session and tokens
      sessionStorage.removeItem('currentUser');
      window.removeEventListener('storage', this.storageEventListener);
      this.logout()
    }
  }

}
