
<p-dialog [(visible)]="showLegalAck" [closable] [modal]="true" position="top" width="880">
  <ng-template pTemplate="header">
      <h4>IMPORTANT - READ CAREFULLY BEFORE USING TRUNUMBER PROTECT</h4>
  </ng-template>
  <div style="overflow-y: auto; height:460px;">
      <section>
              <p class="sub-header"><strong>ICONECTIV, LLC ("iconectiv") AGREEMENT</strong></p>
              <span>BY CLICKING 'YES' YOU, AS AUTHORIZED AGENT OF THE CUSTOMER USING TRUNUMBER PROTECT, CONFIRM YOU HAVE READ, ACCEPT AND
                  AGREE TO BE BOUND BY ALL THE TERMS BELOW PRIOR TO USING ("AGREEMENT").</span>
      </section>
      <section>
          <p class="text-center margin-top sub-header"><strong>SUCH ACCEPTANCE IS BINDING ON THE CUSTOMER.</strong></p>
          <span>YOU SHOULD READ THIS AGREEMENT CAREFULLY BEFORE CHECKING THE BOX. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS DO NOT USE THE SERVICE.
              USE OF THE SERVICE IS DEEMED ACCEPTANCE OF ALL OF THE TERMS AND CONDITIONS.</span>
      </section>
      <section>
          <p class="text-center margin-top sub-header"><strong>WARRANTY DISCLAIMER</strong></p>
          <ol>
              <li><strong>iconectiv</strong> makes no representations and extends no warranties of any kind.</li>
              <li><strong>THE SERVICE IS PROVIDED “AS IS”</strong> AND <strong>ICONECTIV</strong> AND ITS SUPPLIERS MAKE NO REPRESENTATIONS AND EXTENDS NO WARRANTIES,
                  CONDITIONS, REPRESENTATIONS, GUARANTEES OR TERMS (EXPRESS OR IMPLIED, WHETHER BY STATUTE, COMMON LAW, CUSTOM, USAGE OR OTHERWISE) AS TO ANY MATTER
                  INCLUDING PERFORMANCE, RESULTS, SECURITY, NONINFRINGEMENT, MERCHANTABILITY, INTEGRATION, QUIET ENJOYMENT, SATISFACTORY QUALITY, AND FITNESS FOR ANY
                  PARTICULAR PURPOSE OF ANY KIND AND ASSUMES NO RESPONSIBILITY OR LIABILITY WITH RESPECT TO THE SERVICE.</li>
              <li><strong>ICONECTIV</strong> AND ITS SUPPLIERS DISCLAIM ALL OTHER WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                  WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, EVEN IF <strong>ICONECTIV</strong> HAS BEEN MADE AWARE OF SUCH PURPOSE,
                  WARRANTIES ALLEGED TO ARISE AS A RESULT OF CUSTOM AND USAGE, AND THE WARRANTY AGAINST INFRINGEMENT OF PATENTS OR OTHER INTELLECTUAL PROPERTY RIGHTS.</li>
              <li>IN NO EVENT SHALL <strong>ICONECTIV</strong> BE LIABLE TO CUSTOMER FOR ANY DAMAGES ARISING OUT OF THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION,
                  DIRECT/INDIRECT DAMAGES, INCLUDING ANY LOST PROFITS OR OTHER INCIDENTAL OR CONSEQUENTIAL DAMAGES, ARISING OUT OF THIS AGREEMENT AND/OR THE USE OR
                  INABILITY TO USE THE SERVICE.</li>
              <li>YOU ACKNOWLEDGE AND AGREE THAT ICONECTIV HAS NO EXPRESS OR IMPLIED OBLIGATION TO CONTINUE TO MAKE THE SERVICE OR ANY FEATURE THEREOF AVAILABLE NOR
                  INTRODUCE ANY PRODUCTS OR SERVICES COMPATIBLE WITH THE SERVICE.</li>
              <li>Customer shall protect, indemnify, hold harmless and defend iconectiv, with counsel acceptable to iconectiv (at Customer's own expense), from and
                  against any and all direct and indirect losses, damages, obligations, fines, claims, suits, actions or proceedings, and any judgment, settlement,
                  compromise or resolution for damages or any other relief resulting therefrom, to the extent that there is any allegation of any losses or damages,
                  whether direct, indirect, consequential or special (including, but not limited to, personal injury, property damage, economic loss or consequential
                  damages), or their equivalent, based on or in connection with this Agreement, or relating to Customer's use of the Service, regardless of the legal,
                  equitable or factual nature thereof.</li>
              <li>The Service is proprietary and the intellectual property of <strong>iconectiv</strong>. No title is transferred to Customer by this Agreement.</li>
              <li>No  right  is granted: (i)  to reproduce, modify, translate or disassemble the Service; (ii) for any use by any third party of the Spreadsheet;
                  (iii) to resell, distribute, export, re-export, or  sublicense the Service.</li>
              <li>No right is granted to make the Service available on a network or to simultaneous users or through remote access technology.</li>
              <li>Any rights not expressly granted in this license are reserved to <strong>iconectiv</strong>.</li>
              <li>In granting this license to the Customer, <strong>iconectiv</strong> retains its right to enforce its trademarks, copyrights, patents, trade secrets
                  and any other rights directly against Customer.</li>
              <li>As applicable, Customer shall limit use of the Service to the uses provided for in the specific license grant as set forth in Customer's commercial
                  Data Distribution Agreement.</li>
              <li>This Agreement will be governed by and construed in accordance with the laws of New Jersey without giving effect to the principles of conflict of laws
                  thereof, and to the extent permitted by applicable law, all parties consent to the jurisdiction of courts situated in New Jersey in any action arising
                  under this  Agreement. This Agreement will not be governed by the conflict of law rules of any jurisdiction or the United Nations Convention on Contracts
                  for the International Sale of Goods, the application of which is expressly excluded.</li>
              <li>If a term or condition of this Agreement is invalid or unenforceable, the remaining terms and conditions will remain in full force and effect.</li>
          </ol>
      </section>
      <p><strong>END OF GENERAL TERMS AND CONDITIONS</strong></p>        
  </div>    
  <ng-template pTemplate="footer">
      <div class="margin-top pull-right">
          <button id="loginAgreeButton" type="button" pButton (click)="agreed()" label="Yes"></button>
          <button id="loginDisagreeButton" class="noBtn" type="button" pButton (click)="rejected()" label="No"></button>        
      </div>
  </ng-template>
</p-dialog>


