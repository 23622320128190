import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
//import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import '../util/rxjs-operators';
import { JsonHelper } from '../util/json.helper';
import { Logger } from '../services/logger.service';
import { AppConstants, CB } from '../util/index';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { ResponseJson, EditItem, ListDefinition, ListUploadRequest, ListDetails } from '../models/index';
//const Stream = require('ts-stream').Stream;

@Directive()
@Injectable()
export class ListsService {
  blocking = false;
  stopBatchLoading = false;
  isBlankList = true;
  listDataQueryStatus = '';
  maxUploadSize = AppConstants.MaxListUploadFileSize;
  selectedList: ListDefinition;
  selectedListItem: EditItem<ListDefinition>;
  uploadURL: string = AppConstants.UploadListFile;
  displayUploadWin = false;
  //activeSelection: SelectItem[] = [];
  //countrySelection: SelectItem[] = [];
  //ccndcSelection: SelectItem[] = [];
  //isoSelection: SelectItem[] = [];
  pollListStatusUpdate: Subscription;
  listId = [];
  //checkStatusSubscription: Subscription;
  listUploadStatus = '';
  showError = false;
  isNewList = false;
  hideMessageBox = false;
  tempListName = '';
  remainingAllotment = -1;
  selectedListId = -1;
  blockDelete = true;
  isAllowDeleteBL = false;
  isAllowDeleteWL = false;
  isSelectDuringNameEdit = false;
  isEditingNameField = false;
  isSkipDupMsg = false;
  @Output() leftRailChange = new EventEmitter<number>();
  @Output() listChange = new EventEmitter<boolean>();


  constructor(private http: HttpClient, private httpService: HttpService, private log: Logger, private notifService: NotificationService) { };
  
  checkListUploadStatus() {
    this.pollListStatusUpdate = interval(5000).subscribe(() => {
      if (this.selectedList.id) {
        this.httpService.get(AppConstants.QueryLatestListUploadRequest + '?listId=' + this.selectedList.id,
          [this.postQueryUploadRequestHandler]);
      } else {
        this.httpService.get(AppConstants.QueryLatestListUploadRequest + '?listName=' + this.selectedList.listName + '&listType=' + this.selectedList.type,
          [this.postQueryUploadRequestHandler]);
      }

    });
  }
  postQueryUploadRequestHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      const uploadreq = JsonHelper.parse(responseJson.data, ListUploadRequest);
      if (uploadreq.status !== 'process') {
        this.pollListStatusUpdate.unsubscribe();
        this.selectedList.lastUploadStatus = '';
        this.getListDetails();
      }
    }
  }

  async onSelect(myList: ListDefinition) {
    this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    this.selectedList = myList;
    this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
    this.hideMessageBox = true;
    this.blockDelete = false;
    this.toggleDeleteIcon(this.selectedList.type);
    if (myList.id) {
      if (this.selectedList.lastUploadStatus === 'process') {
        //this.checkListUploadStatus();
      } else {
        this.blocking = true;
        this.getListDetails();
      }
    } else {
      this.httpService.post(JsonHelper.toJson(myList), AppConstants.SaveListDefinition, [this.postCreateListHandler]);
    }
  }

  async onCreateNew(myList: ListDefinition, tempName:string) {
    this.stopBatchLoading = true;
    this.hideMessageBox = true;
    this.blockDelete = false;
    await AppConstants.delay(1000);
    this.selectedList = myList;
    this.selectedListItem = new EditItem(this.selectedList, ListDefinition);

    this.isNewList = true;
    this.tempListName = tempName; 
  }

  postCreateListHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);
      this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
    };
  }

  async queryRemainingListAllotment(type:string) {
    let url = AppConstants.QueryRemainingListAllotment;
    url += '/' + type;
  
    //console.log('query remaining: ' + url);
    this.httpService.get(url, [this.postQueryRemainingListAllotment]);
  }
  
  postQueryRemainingListAllotment  = (responseJson: ResponseJson) => {
     if (responseJson.status === AppConstants.SUCCESS) {
       this.remainingAllotment = responseJson.data.remainingAllotment;
     }
  }
  
  hasUploadError() {
    if (this.selectedList.listUploadRequests && this.selectedList.listUploadRequests.length > 0) {
      if (this.selectedList.listUploadRequests[0].errorData) {
        //this.showError = true;
        return true;
      }
    }
    //this.showError = false;
    return false;
  }

  /* updateReadStatus() {
    this.httpService.post(JsonHelper.toJson(items), AppConstants.UpdateReadStatus, [this.postUpdateReadStatus]);
    this.showError = false;
  }

  postUpdateReadStatus = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.FAIL) {
      this.selectedDetailItem.reset();
    }
  } */
 
  getListDetails() {
    //console.log("getListDetails(): Name|ID: " + this.selectedList.listName + " | " + this.selectedList.id);
    this.listDataQueryStatus = '';
    //this.selectedList.listData = [];
    this.listId = [];
    this.stopBatchLoading = false;
    this.httpService.get(AppConstants.QueryListDetailData + '?id=' + this.selectedList.id + '&pageNo=0&limit=' + AppConstants.pageSize,
      [this.postQueryListDetailDatatHandler]);
  }

  postQueryListDetailDatatHandler = (responseJson: ResponseJson) => {
    this.blocking = false;
    if (responseJson.status === AppConstants.SUCCESS) {
      const listData: Array<ListDetails> = JsonHelper.parseArray(responseJson.data, ListDetails);
      listData.forEach(item => {
        if (this.listId.indexOf(item.id) < 0) {
          if (item.customerDate) {
            var now = new Date(item.customerDate);
            item.customerDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
          }
          if (this.selectedList.listData === undefined) {
            this.selectedList.listData = [];
          }
          this.selectedList.listData.push(item);
          this.listId.push(item.id);
        }
      });

      if (responseJson.last || this.stopBatchLoading) {
        this.log.debug('reached the last page: ' + this.selectedList.listData.length);
        this.log.debug('listID size: ' + this.listId.length);
        this.log.debug('isLastPage: ' + responseJson.last + ', stopBatchLoading:' + this.stopBatchLoading);
        this.selectedListItem.update();

        if (responseJson.last) {
          this.listDataQueryStatus = '';
        } else {
          this.listDataQueryStatus = 'Total records ' + this.selectedList.listData.length;
        }
      } else {
        const pageNo = responseJson.pageNumber + 1;
        this.listDataQueryStatus = 'Loading ....' + this.selectedList.listData.length + ' of ' + responseJson.totalCount;
        this.log.debug('page: ' + pageNo + ',' + this.listDataQueryStatus);
        this.httpService.get(AppConstants.QueryListDetailData + '?id=' + this.selectedList.id + '&limit=' + AppConstants.pageSize
          + '&pageNo=' + pageNo, [this.postQueryListDetailDatatHandler]);
      }
      this.listChange.emit(true);
    }
  }

/* Don't see this referenced in the system
  createSelectionItems() {
    const countryValues: Array<string> = [];
    const ccndcValues: Array<string> = [];
    const isoValues: Array<string> = [];

    Stream.from(this.selectedList.listData).map(item => {
      if (countryValues.indexOf(item.termCountry) < 0) {
        countryValues.push(item.termCountry);
      }

      if (ccndcValues.indexOf(item.ccNdc) < 0) {
        ccndcValues.push(item.ccNdc);
      }

      if (isoValues.indexOf(item.iso2) < 0) {
        isoValues.push(item.iso2);
      }
    });

    this.activeSelection = [];
    this.activeSelection.push({ label: '', value: null });
    this.activeSelection.push({ label: 'Active', value: true });
    this.activeSelection.push({ label: 'InActive', value: false });

    this.countrySelection = [];
    this.countrySelection.push({ label: '', value: null });
    countryValues.forEach(value => {
      this.countrySelection.push({ label: value, value: value });
    });

    this.ccndcSelection = [];
    this.ccndcSelection.push({ label: '', value: null });
    ccndcValues.forEach(value => {
      this.ccndcSelection.push({ label: value, value: value });
    });

    this.isoSelection = [];
    this.isoSelection.push({ label: '', value: null });
    isoValues.forEach(value => {
      this.isoSelection.push({ label: value, value: value });
    });

    this.log.debug(this.activeSelection);
  }
  */

  upload(event, file: File) {
    event.formData = new FormData();
    event.formData.append('listName', this.selectedList.listName);
    event.formData.append('description', this.selectedList.description);
    if (this.selectedList.id) {
      event.formData.append('listId', this.selectedList.id);
    } else {
      event.formData.append('listId', '');
    }
    event.formData.append('listType', this.selectedList.type);
    event.formData.append('delimiter', AppConstants.DELIMITER);
    event.formData.append('file', file);
    this.blocking = true;
    //let headers = new HttpHeaders();    
    // const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //headers.append('Authorization', "Bearer " + currentUser.token);
    //let options = new RequestOptions({ headers: headers }); 
    //res.json()-> res
    //this.http.post(AppConstants.UploadListFile, event.formData, options).map((res) => res).subscribe(

    this.http.post(AppConstants.UploadListFile, event.formData, { 
      headers: new HttpHeaders()
        // .append('Authorization', "Bearer " + currentUser.token)
    }).pipe(map((res) => res)).subscribe(
      (success) => {
        if (success['status'] === 'success') {
          this.notifService.add({ severity: 'info', summary: 'List Upload', detail: 'The addition to list ' + this.selectedList.listName + ' is confirmed.' });
          this.queryListStatusUpdate(this.selectedList);
        } else {
          this.notifService.add({ severity: 'error', summary: 'List Upload', detail: success['message'] });
          this.log.error('Error on upload file: ' + event);
        }
        this.displayUploadWin = false;
        this.blocking = false;
      },
      (error) => {
        this.displayUploadWin = false;
        this.blocking = false;
        this.log.error('Error on upload file: ' + event);
        }
    )
  }

  onCancel() {
    this.selectedListItem.reset();
    this.displayUploadWin = false;
  }


  queryListStatusUpdate(selectedList: ListDefinition) {
    this.selectedList = selectedList;
    let url = AppConstants.QueryLatestListUploadRequest;

    if (selectedList.id) {
      url += '?listId=' + selectedList.id;
    } else {
      url += '?listName=' + selectedList.listName + '&listType=' + this.selectedList.type;
    }

    this.log.debug('query upload status: ' + url);
    this.httpService.get(url, [this.postQueryLatestListUploadRequestHandler]);
  }


  postQueryLatestListUploadRequestHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.notifService.add({ severity: 'info', summary: 'Success', detail: 'The addition to list ' + this.selectedList.listName + ' is confirmed.' });

      const listUploadRequest: ListUploadRequest = JsonHelper.parse(responseJson.data, ListUploadRequest);
      if (listUploadRequest) {
        this.listUploadStatus = listUploadRequest.status;
        this.log.debug('list upload status: ' + this.listUploadStatus);

        if (this.listUploadStatus === 'fail') {
          this.queryListDefinition();
          //this.onSelect(this.selectedList);
        } else if (this.listUploadStatus === 'complete') {
          this.queryListDefinition();
        } else {
        }
      }
    } else if (responseJson.status === AppConstants.FAIL) {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: responseJson.message });
    }

  }

  queryListDefinition() {
    this.blocking = false;
    let url = AppConstants.QueryListDefinition;

    if (this.selectedList.id) {
      url += '?listId=' + this.selectedList.id;
    } else {
      url += '?listName=' + this.selectedList.listName + '&listType=' + this.selectedList.type;
    }

    //this.log.debug('query list definition: ' + url);
    this.httpService.get(url, [this.postQueryListDefinitionRequestHandler]);
  }

  postQueryListDefinitionRequestHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);
      this.getListDetails();
      this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
    }
  }

  alertLeftRailUpdate() {
    this.leftRailChange.emit(this.selectedList.id);
  }
  
  toggleDeleteIcon(deleteType: string) {
    if (this.blockDelete) {
      deleteType=null;
    } 

    if (deleteType == 'BL') {
      this.isAllowDeleteBL = true;
      this.isAllowDeleteWL = false;
    } else if (deleteType == 'WL') {
      this.isAllowDeleteBL = false;
      this.isAllowDeleteWL = true;
    } else {
      this.isAllowDeleteBL = false;
      this.isAllowDeleteWL = false;
    }
    
  }

  resetListName() {
    if (this.selectedList.listName != this.selectedListItem.origItem.listName) {
      this.selectedList.listName = this.selectedListItem.origItem.listName;
    }
  }

}