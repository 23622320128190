import { Component } from '@angular/core';
import {AuthService} from '../auth';
import { ResponseJson, User } from 'app/models';
import { CacheService, HttpService, Logger, NotificationService } from 'app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'app/services/event.service';
import { ListsService } from 'app/lists/lists.service';
import { AppConstants, JsonHelper } from 'app/util';
import { InactivityService } from 'app/services/inactivity.service';

@Component({
  templateUrl: 'home.html',
})
export class HomeComponent {

  isAuthenticated!: boolean;
  user!: User;
  showLegalAck = false;

  constructor(public auth: AuthService,
      private log: Logger, private route: ActivatedRoute, private router: Router, public notifService: NotificationService,
      private httpService: HttpService, 
      private cacheService: CacheService, 
      private eventService: EventService,
      private listsService: ListsService
      
      
  ) {
  }

  async ngOnInit() {
    this.isAuthenticated = await this.auth.isAuthenticated();
    // this.auth.getUser().subscribe(data => this.user = data);
    this.user = this.auth.loginUser;
    this.cacheService.cacheInactivityInterval();

    if (this.user.showTerms) {
      this.showLegalAck = true;
    } else {
      this.agreed();
     
    }
  }


  async agreed() {
    this.showLegalAck = false;
    sessionStorage.setItem('currentUser', JsonHelper.toJson(this.auth.loginUser));
    this.log.debug('Successful logged in');
    console.log("Going to getGuiPolling");
    this.getGuiPollingEnabledSetting();


    if (!this.user.active){
      this.router.navigate(['/error-page']);
      return;
    }
    

    // await AppConstants.delay(800);
    // console.log("Back from getGuiPolling");
    if (this.auth.loginUser.hasList) {
      this.listsService.onSelect(this.auth.loginUser.listDefinition);
      this.router.navigate(['/lists/listDetail']);
    } else {
      //this.router.navigate([this.returnUrl]);
      this.router.navigate(['/lists']);
    }
    
    this.cacheService.cacheCountry();
    this.cacheService.cacheProviders();
    this.cacheService.cacheTos();
    this.cacheService.cacheCountryProvider();
    this.cacheService.queryUserEvents();
    this.eventService.pullUserEvents();
    this.cacheService.cacheMsrnProviders();
    //this.eventService.connect(this.authService.loginUser);
  }

  getGuiPollingEnabledSetting() {
    //console.log("STARTED getGuiPollingEnabledSetting");
    this.httpService.get(AppConstants.QueryGuiPollingEnabled, [this.postQueryGuiPollingEnabledHandler]);
    //console.log("AFTER the getGuiPollingEnabledSetting");
  }


  postQueryGuiPollingEnabledHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      if (responseJson.message == 'true') {
        this.auth.loginUser.guiPollingEnabled = true;
      } else {
        this.auth.loginUser.guiPollingEnabled = false;
      }
    } else {
      //this.notifService.add({ severity: 'error', summary: 'Error', detail: responseJson.message });
      this.auth.loginUser.guiPollingEnabled = true;
    }
    //console.log("postQuery after: " + this.authService.loginUser.guiPollingEnabled);
    
  }



  userInfo() : string {
    return JSON.stringify(this.user, null, 2);
  }



}

