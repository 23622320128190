import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent {

  errorMsg:string = "";

  constructor(private renderer: Renderer2, private auth:AuthService, private route: ActivatedRoute) { }


  

  ngOnInit(): void {
    this.renderer.setStyle(document.body, 'margin-bottom', '0px !important');
    this.route.queryParams.subscribe(params => {
      const msg = params['errorMsg'];
      console.log("msg=" + msg);

      if (msg){
        this.errorMsg = msg;
      }
      else if (this.auth.isValid()){
        this.errorMsg = "Customer Deactivated";
      }
      else {
        this.errorMsg = "Account Configuration Error";
      }


    });

  }

  ngOnDestroy(): void {
    this.renderer.removeStyle(document.body, 'margin-bottom');
  }

  errmsg():string{
    console.log("Printing errorMsg=" + this.errorMsg);
    return this.errorMsg;
  }

}
