import { Injectable, OnInit } from '@angular/core';
import {Message, MessageService} from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { interval } from 'rxjs';
import '../util/rxjs-operators';
import { JsonHelper } from '../util/json.helper';
import { Logger } from '../services/logger.service';
import { AppConstants, CB } from '../util/index';
import { HttpService } from '../services/http.service';
import { ResponseJson, EditItem, ListDefinition, ListUploadRequest, ListDetails } from '../models/index';
import { AuthService } from '../auth';


// inactivity.service.ts
import { NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, fromEvent, takeUntil, switchMap } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService implements OnInit {
  private destroy$ = new Subject<void>();
  private timeout$ = new Subject<void>();
  private timeoutInterval = 0; 
  
  constructor(private router: Router, private ngZone: NgZone, private authService: AuthService, private cacheService: CacheService,) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  startWatching(timeoutInterval) {
   
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'mousemove')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.resetTimer());

      fromEvent(document, 'keydown')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.resetTimer());
      
      fromEvent(document, 'wheel')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.resetTimer());
    });

    this.timeoutInterval= Number(this.cacheService.timeoutInterval);
console.log("this.timeoutInterval:"+this.timeoutInterval);
    this.timeoutInterval=(timeoutInterval) * 60 * 1000; //convert minutes to milliseconds
    this.resetTimer();
  }

  private resetTimer() {
    this.timeout$.next(); // Cancel any existing timer
    interval(this.timeoutInterval)
      .pipe(
        takeUntil(this.timeout$),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.ngZone.run(() => this.logout());
      });
  }

  private logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.timeout$.complete();
  }

 
}