<div class="row top-left-margin">
    <div class="col-md-3 w3-margin-left">
        <h2>TruNumber Protect</h2>
        <h6>Stop struggling to sort through false positives in fraud detection</h6>
        <br/><br/>
        <a class="btn btn-default center-block width-160 w3-margin-top" href="https://iconectiv.com/trunumber/protect" target="_blank">Learn More</a>
    </div>

    <div class="offset-sm-4 col-sm-3">
        <p style="font-size: 24px"><strong>Log in to TruNumber Protect</strong></p>
            <form class="formLogin" (ngSubmit)="login()" >
            <button title='Log In' type="submit" class="d-block mx-auto btn btn-primary" id="loginBtn" name="loginBtn">Log In</button>
              <div class="mb-3">
                  <h4 id="loginErrorMessage" [ngClass]="style" *ngIf="message">{{message}}</h4>
              </div>
            </form>
        <div>
            <p i18n style="font-size: xx-small">This system is restricted to official business and subjected to monitoring at any time. Anyone using this system expressly consents to such monitoring and to any evidence of unauthorized access or activity being used for criminal prosecution.
            </p>
        </div>
    </div>
</div>

